<script>
let SHA256 = require("crypto-js/sha256");


function OnProcessMessage({context, response})
{
  const { message } = response;

  context.waitResponse = false;

  switch(message)
  {
    case "user_restricted":
    case "invalid_credentials":
    {
      context.status = context.$lan(message);
      break;
    }


    case "login_token":
    {
      const { token } = response.data;
      context.$session.SetSessionToken(token);
      context.$session.LoadSession(context.OnSessionLoad);
      break;
    }

    default:
      {
        console.warn(message);
      }
      break;
  }
}

export default 
{
  name: 'Login',

  data() { return {
    emailInput: "",
    passwordInput: "",

    waitResponse: false,
    status: "",
  }}, 

  created() 
  {
    this.CheckSession();
  },

  methods: {
    Login() 
    {
      this.waitResponse = true;
      this.status = "";

      const data = { 
        email: this.emailInput, 
        password: SHA256(this.passwordInput).toString() 
      };

      this.$api.post({ context: this, path: "login", data}, OnProcessMessage);
    },

    OnSessionLoad()
    {
      this.$router.push('/');
    },

    CheckSession()
    {
      if (this.$session.active && window.$cookies.get('SSID'))
      {
        this.$router.push("/");
      }
    }
  }

}
</script>

<template>
  <div class="login-body">
    <form @submit.prevent="Login">
      <div class="login-board">
        <h2>Login</h2>
        <input maxlength="50" class="input" placeholder="email@domain.com" v-model="emailInput" type="text" required>
        <input maxlength="30" class="input" placeholder="Password" v-model="passwordInput" type="password" required>
        <l-button :active="waitResponse" class="border-btn loginBtn blue" type="submit">
          Login
        </l-button>
        <hr />

        <router-link class="button btn blue-fill" to="register" v-if="$global.config.register">
          Creează Account
        </router-link>  
        <div v-if="status" v-on:click="status=''" class="box status-box red">{{status}}</div>
      </div>
    </form>
  </div>
</template>

<style scoped>
.code-input {
  font-size: 16px;
  letter-spacing: 1em;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
}
</style>

<style scoped src="./public.css"></style>

